import React from "react";
import { Helmet } from "react-helmet";
import PrivacyPolicyAccordion from "../components/PrivacyPolicyAccordion";
import { Heading } from "../components/styles/Heading.styled";
import styled from "styled-components";
import { Wrapper } from "../components/styles/Wrapper.styled";

const BodyWrapper = styled.div`
  padding: 0;
  font-family: Roboto, sans-serif;
  font-weight: 200;
  font-size: 1rem;
`;

export default function PrivacyPolicy() {
  return (
    <Wrapper>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          description="BPO Group and its subsidiaries respects the privacy of all users and is dedicated to protecting your privacy.  We are committed to business practices in compliance with the Protection of Personal Information Act (POPIA) and in line with industry standards. This privacy policy explains how the company may obtain and use your personal information."
        />
      </Helmet>
      <Heading variant="h1" component="h1">
        Privacy Policy
      </Heading>
      <BodyWrapper>
        <PrivacyPolicyAccordion></PrivacyPolicyAccordion>
      </BodyWrapper>
    </Wrapper>
  );
}
