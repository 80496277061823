import React from "react";
import styled from "styled-components";

const Wrapper = styled.button`
  display: inline-block;
  cursor: pointer;
  background: none;
  border: none;

  @media ${({ theme }) => theme.mindevice.laptop} {
    display: none;
  }
`;

const TopLine = styled.div`
  width: 35px;
  height: 5px;
  background-color: ${(props) =>
    props.changeColor && !props.extendNavbar ? "#0D0C1D" : "#e1e9fc"};
  margin: 6px 0;
  transition: 1s;
  transform: ${({ open }) =>
    open
      ? "rotate(45deg) translate(8px, 8px);"
      : "rotate(0deg) translate(0px, 0px);"};
`;

const MiddleLine = styled.div`
  width: 35px;
  height: 5px;
  background-color: ${(props) =>
    props.changeColor && !props.extendNavbar ? "#0D0C1D" : "#e1e9fc"};
  margin: 6px 0;
  transition: 1s;
  opacity: ${({ open }) => (open ? "0" : "1")};
`;

const BottomLine = styled.div`
  width: 35px;
  height: 5px;
  background-color: ${(props) =>
    props.changeColor && !props.extendNavbar ? "#0D0C1D" : "#e1e9fc"};
  margin: 6px 0;
  transition: 1s;
  transform: ${({ open }) =>
    open
      ? "rotate(-45deg) translate(8px, -8px);"
      : "rotate(0deg) translate(0px, 0px);"};
`;

export default function BurgerButton(props) {
  return (
    <Wrapper onClick={props.onClick}>
      <TopLine
        open={props.open}
        changeColor={props.changeColor}
        extendNavbar={props.extendNavbar}
      ></TopLine>
      <MiddleLine
        open={props.open}
        changeColor={props.changeColor}
        extendNavbar={props.extendNavbar}
      ></MiddleLine>
      <BottomLine
        open={props.open}
        changeColor={props.changeColor}
        extendNavbar={props.extendNavbar}
      ></BottomLine>
    </Wrapper>
  );
}
