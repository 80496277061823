import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavbarContainer = styled.header`
  position: fixed;
  display: inline-block;
  z-index: 99;
  width: 100%;
  height: ${(props) => (props.extendNavbar ? "100vh" : "80px")};
  background-color: ${(props) =>
    props.changeColor && !props.extendNavbar ? "#2f3c69" : "#2f3c69"};
  transition: 0.5s;
  font-family: ${({ theme }) => theme.font.menu};

  @media ${({ theme }) => theme.mindevice.laptop} {
    height: 80px;
  }
`;

export const LeftContainer = styled.div`
  z-index: 99;
  flex: 10%;
  display: flex;
  padding-left: 5%;
`;

export const RightContainer = styled.div`
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0 1%;
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavbarImage = styled.img`
  margin: 10px;
  max-width: 150px;
  height: auto;

  @media ${({ theme }) => theme.maxdevice.laptop} {
    display: none;
  }
`;

export const NavbarImageSmall = styled.img`
  display: none;
  margin: 10px;
  max-width: 45px;
  height: auto;

  @media ${({ theme }) => theme.mindevice.tablet} {
    display: none;
  }

  @media ${({ theme }) => theme.mindevice.laptop} {
    padding: ${(props) => props.theme.space[3]};
    display: flex;
  }
`;

export const NavbarImageLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.mindevice.laptop} {
    display: none;
  }
`;

export const NavLinkWrapper = styled(Link)`
  text-decoration: none !important;
`;

export const NavLink = styled.div`
  display: none;
  color: ${(props) => (props.changeColor ? "#e1e9fc" : "#e1e9fc")};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: ${({ theme }) => theme.size.headerHeight};
  max-width: 100%;

  &:hover {
    background: ${(props) =>
      props.changeColor && !props.extendNavbar ? "#445078" : "#445078"};
    color: ${(props) => (props.changeColor ? "#ec9307" : "#ec9307")};
    cursor: pointer;
  }

  @media ${({ theme }) => theme.mindevice.tablet} {
    display: none;
  }

  @media ${({ theme }) => theme.mindevice.laptop} {
    padding: ${(props) => props.theme.space[3]};
    font-size: x-large;
    display: flex;
  }
`;

export const ExtendedNavLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.colors.menuText};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: ${({ theme }) => theme.size.headerHeight};
  max-width: 100%;
  text-decoration: none !important;
  font-size: large;

  &:hover {
    background: ${(props) =>
      props.changeColor && !props.extendNavbar ? "#514545" : "#514545"};
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
  }
`;

export const NavDropdownLinkWrapper = styled(Link)`
  text-decoration: none !important;
`;

export const NavDropdownLink = styled.div`
  display: flex;
  background: ${(props) =>
    props.changeColor && !props.extendNavbar ? "#445078" : "#445078"};
  color: ${(props) => (props.changeColor ? "#e1e9fc" : "#e1e9fc")};
  height: 60px;
  padding: 10px;
  align-items: center;
  font-size: large;

  &:hover {
    background: ${(props) =>
      props.changeColor && !props.extendNavbar ? "#596387" : "#596387"};
    color: ${(props) => (props.changeColor ? "#ec9307" : "#ec9307")};
    cursor: pointer;
  }
`;

export const ExtendedNavDropdownLink = styled(Link)`
  display: flex;
  background: ${({ theme }) => theme.colors.menuDropdownBackground};
  height: 60px;
  padding: 20px;
  align-items: center;
  text-decoration: none !important;
  color: ${({ theme }) => theme.colors.menuDropdownText};
  font-size: medium;

  &:hover {
    background: ${({ theme }) => theme.colors.tertiaryDark};
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
  }
`;

export const NavDropdownArrowWrapper = styled.div`
  padding: 15px;
`;

export const NavLinkLabel = styled.span`
  padding: 10px;
`;

export const ExtendedNavLinkLabel = styled.span`
  margin-left: 16px;
  align-items: center;
`;
