import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
`;

const Heading = styled.div`
  font-size: 17;
  flex-basis: 33.33%;
  flex-shrink: 0;
  padding: 0 2%;
`;

const SecondaryHeading = styled.div`
  font-size: 15;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0 2%;
`;

const Body = styled.div`
  font-size: 15;
  padding: 0 20px;
`;

export default function PrivacyPolicyAccordion() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Wrapper>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Heading>Overview</Heading>
          <SecondaryHeading>An overview of the Privacy Policy</SecondaryHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Body>
            <ol type="1" className="ordered-list">
              <li>
                BPO Group and its subsidiaries respects the privacy of all users
                and is dedicated to protecting your privacy. We are committed to
                business practices in compliance with the Protection of Personal
                Information Act (POPIA) and in line with industry standards.
              </li>
              <li>
                This privacy policy explains how the company may obtain and use
                your personal information, including:
              </li>
              <ol type="i" className="nested-ordered-list">
                <li>The type of information we collect</li>
                <li>The methods used to collect it</li>
                <li>How it is used</li>
                <li>How it is protected</li>
              </ol>
              <li>
                Please read our Privacy Policy carefully and let us know if you
                have any questions by mailing us at{" "}
                <a href="mailto:infosec@bpogroup.co.za">
                  infosec@bpogroup.co.za
                </a>
                .
              </li>
            </ol>
          </Body>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Heading>Collection of Personal Information</Heading>
          <SecondaryHeading>
            How we might obtain your Personal Information
          </SecondaryHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Body>
            <ol type="1" className="ordered-list">
              <li>
                BPO Group collects information on all its visitors using our
                website. When you register or send us your information via email
                you are no longer anonymous to us and we link all associated
                data to your name and account and may store this information
                indefinitely.
              </li>
              <li>
                We may collect personal information such as your contact
                information (name, phone, email, etc.)
              </li>
              <li>
                We may also collect navigational information such as the type of
                browser used to access our services, the pages you viewed, and
                links you clicked.
              </li>
              <li>
                In addition, BPO Group may collect demographic information such
                as the Operating System of your computer, the time of day and
                the IP used to access our services.
              </li>
              <li>
                In the event that you contact any member of the BPO Group team
                via telephone, email, chat, and/or social media, your identity
                and conversation (written or audio), may be recorded.
              </li>
              <li>
                Some of the personal information may be mandatory. In the event
                you decline to provide mandatory information, BPO Group may not
                be able to assist you.
              </li>
            </ol>
          </Body>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Heading>Using your Personal Information</Heading>
          <SecondaryHeading>
            How we may use your Personal Information
          </SecondaryHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Body>
            <ol type="1" className="ordered-list">
              <li>
                BPO Group uses your personal information for many reasons:
              </li>
              <ol type="i" className="nested-ordered-list">
                <li>Creating and managing your online account</li>
                <li>In order to assist you with any of our products</li>
                <li>Processing transactions</li>
                <li>Providing customer support</li>
                <li>
                  To send you marketing, informational and operational emails.
                  If you wish to opt out of marketing emails you may update your
                  profile preferences in the settings of your account.
                </li>
              </ol>
            </ol>
          </Body>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Heading>Sharing Information with Third Parties</Heading>
          <SecondaryHeading>
            Under what circuimstances we might share your Personal Information
          </SecondaryHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Body>
            <ol type="1" className="ordered-list">
              <li>
                We will process and share your personal information with third
                parties where:
              </li>
              <ol type="i" className="nested-ordered-list">
                <li>
                  Processing is necessary to conclude a contract with you or
                  provide a service to you
                </li>
                <li>You have agreed to it</li>
                <li>
                  We are required and/or compelled to do so by law or local
                  government authorities
                </li>
                <li>
                  Processing is necessary to protect the legitimate rights or
                  interests of the company
                </li>
              </ol>
              <li>
                We will never sell or trade your personal information to any
                third party.
              </li>
            </ol>
          </Body>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Heading>Retention</Heading>
          <SecondaryHeading>
            How long do we retain your Personal Information
          </SecondaryHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Body>
            <ol type="1" className="ordered-list">
              <li>
                We will retain your personal information as required by law and
                for as long as necessary to conclude the service that it was
                obtained for.
              </li>
              <li>Contractual voice recordings are stored for 5 years.</li>
            </ol>
          </Body>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Heading>Security</Heading>
          <SecondaryHeading>
            What we do to protect your Personal Information
          </SecondaryHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Body>
            <ol type="1" className="ordered-list">
              <li>
                We undertake to implement all reasonable measures to ensure the
                security and confidentiality of your personal information in
                line with POPIA.
              </li>
              <li>
                All personal information is stored on encrypted and password
                protected databases and are tightly controlled.
              </li>
              <li>
                In the event of a security breach, we will inform you by
                contacting you on the contact details we have available, by
                press release, or by any other reasonably suitable method.
              </li>
            </ol>
          </Body>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Heading>Your Rights</Heading>
          <SecondaryHeading>
            What are your rights with regards to your Personal Information
          </SecondaryHeading>
        </AccordionSummary>
        <AccordionDetails>
          <Body>
            <ol type="1" className="ordered-list">
              <li>
                You have the right to access your personal information that we
                hold on you. If you apply in writing to receive a copy of the
                personal information that we hold, we will implement security
                and verification procedures to ensure that we provide the record
                to the correct person. We may be allowed in law to charge a fee
                for this and may refuse the information on certain legal
                grounds.
              </li>
              <li>
                You may at any time request that we update, correct or destroy
                your personal information by communicating with us through our
                website.
              </li>
              <li>
                If you believe that we have breached this policy, you can lodge
                a complaint on our website. We will investigate the matter and
                may use third party advisors during this process. You can also
                complain to the Information Regulator.
              </li>
            </ol>
          </Body>
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
}
