import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Context
import MyProvider from "../context/MyProvider";

//Routes
import About from "../routes/About";
import Contact from "../routes/Contact";
import Footer from "./Footer";
import Header from "./Header";
import PrivacyPolicy from "../routes/PrivacyPolicy";
import Root from "../routes/Root";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/Theme";

const history = createBrowserHistory();

function App() {
  return (
    <div className="main-container">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Header />
          <MyProvider>
            <Routes>
              <Route path="/" element={<Root />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            </Routes>
          </MyProvider>
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
