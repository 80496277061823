import React, { useState, useEffect } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { NavLinkData } from "../data/NavLinkData";
import BurgerButton from "./BurgerButton";
import ExtendedNavLinks from "./ExtendedNavLinks";
import {
  NavbarContainer,
  LeftContainer,
  RightContainer,
  NavbarInnerContainer,
  NavbarExtendedContainer,
  NavbarImageLink,
  NavbarImage,
} from "./styles/Navbar.styled";
import NavLinks from "./NavLinks";
import styled from "styled-components";

const ContactNumber = styled.a`
  font-weight: 200;
  padding: 10px;
  margin-right: 20px;
  align-items: center;
  font-size: x-large;
  color: #fff;
  text-decoration: none !important;
  background: transparent;
  border: none;
  outline: none;

  @media ${({ theme }) => theme.mindevice.laptop} {
    display: none;
  }

  &:hover {
    color: #499ab5;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export default function Header() {
  const [extendNavbar, setExtendNavbar] = useState(false);
  const [changeColor, setChangeColor] = useState(false);

  function handleButtonClick() {
    setExtendNavbar(!extendNavbar);
  }

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY > 0) {
      setChangeColor(true);
    } else {
      setChangeColor(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <NavbarContainer extendNavbar={extendNavbar} changeColor={changeColor}>
      <NavbarInnerContainer>
        <LeftContainer>
          <NavbarImageLink to="/">
            <NavbarImage src="../images/bpo-group-logo.png" />
          </NavbarImageLink>
          {NavLinkData.map((item, index) => {
            return (
              <NavLinks item={item} key={index} changeColor={changeColor} />
            );
          })}
          <BurgerButton
            onClick={handleButtonClick}
            open={extendNavbar}
            changeColor={changeColor}
            extendNavbar={extendNavbar}
          ></BurgerButton>
        </LeftContainer>
        <RightContainer>
          <ContactNumber href="tel:0100545575">
            <PhoneIcon /> 0100 545 575
          </ContactNumber>
        </RightContainer>
      </NavbarInnerContainer>
      {extendNavbar && (
        <NavbarExtendedContainer>
          {NavLinkData.map((item, index) => {
            return (
              <ExtendedNavLinks
                item={item}
                key={index}
                setExtendNavbar={setExtendNavbar}
              />
            );
          })}
        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  );
}
