import React, { useState } from "react";
import MyContext from "./MyContext";

function MyProvider({ children }) {
  //Data
  const [data, setData] = useState({
    leadId: "",

    //Personal Info
    title: "",
    idNumber: "",
    firstName: "",
    lastName: "",
    maritalStatus: "",
    application: "",
    mobile: "",
    alternatePhone: "",
    homePhone: "",
    workPhone: "",
    email: "",
    complex: "",
    street: "",
    suburb: "",
    city: "",
    province: "",
    postalCode: "",

    //Joint Applicant Info
    jointApplicantTitle: "",
    jointApplicantIdNumber: "",
    jointApplicantFirstName: "",
    jointApplicantLastName: "",
    jointApplicantMobile: "",
    jointApplicantAlternatePhone: "",
    jointApplicantHomePhone: "",
    jointApplicantWorkPhone: "",
    jointApplicantEmail: "",

    //Debt Counsellor
    debtCounsellor: "",
    debtReviewReason: "",
    isCourtOrder: "",
    courtOrder: "",

    //Credit Providers
    amountOfCreditors: "",
    creditProvider1: "",
    creditProvider1PaidUp: "No",
    creditProvider2: "",
    creditProvider2PaidUp: "No",
    creditProvider3: "",
    creditProvider3PaidUp: "No",
    creditProvider4: "",
    creditProvider4PaidUp: "No",
    creditProvider5: "",
    creditProvider5PaidUp: "No",
    creditProvider6: "",
    creditProvider6PaidUp: "No",
    creditProvider7: "",
    creditProvider7PaidUp: "No",
    creditProvider8: "",
    creditProvider8PaidUp: "No",
    creditProvider9: "",
    creditProvider9PaidUp: "No",
    creditProvider10: "",
    creditProvider10PaidUp: "No",

    //Docs Data
    lastMonth: "",
    lastMonthYear: "",
    debtCounsellorDocs: "",
    idDoc: "",
    payslip: "",
    proofOfResidence: "",

    //Debit Order
    accountHolderName: "",
    accountHolderId: "",
    accountHolderContact: "",
    accountHolderAddress: "",
    accountHolderBank: "",
    accountHolderAccount: "",
    accountHolderBranch: "",
    accountHolderBranchCode: "",
    accountHolderAccountType: "",
    shortName: "DEBTHAVEN",
    contractRef: "",
    firstPaymentDate: "",
    monthlyPaymentDay: "",

    //Declaration
    phoneContactConsent: "No",
    smsContactConsent: "No",
    whatsAppContactConsent: "No",
    dataShareConsent: "No",
    signedLocation: "",
    signedDate: "",
    signedDay: "",
    signedMonth: "",
    signedYear: "",
  });

  //Docs
  const [docsData, setDocsData] = useState({
    leadId: "",
    formName: "",
    formBase64: null,
    mandateName: "",
    mandateBase64: null,
    courtOrderName: "",
    courtOrderBase64: null,
    debtCounsellorDocsName: "",
    debtCounsellorDocsBase64: null,
    idDocName: "",
    idDocBase64: null,
    payslipName: "",
    payslipBase64: null,
    proofOfResidenceName: "",
    proofOfResidenceBase64: null,
  });
  const [docsSubmitted, setDocsSubmitted] = useState(false);
  const [docsSent, setDocsSent] = useState(false);
  const [docsError, setDocsError] = useState(false);
  const [docsResponse, setDocsResponse] = useState(false);

  //API Authentication
  const [headers, setHeaders] = useState(null);

  //Laminin
  const [lamininSubmitted, setLamininSubmitted] = useState(false);
  const [lamininSent, setLamininSent] = useState(false);
  const [lamininError, setLamininError] = useState(false);
  const [lamininResponse, setLamininResponse] = useState({});

  //Forms
  const [creditProvidersInfoCaptured, setCreditProvidersInfoCaptured] =
    useState(false);
  const [debitOrderInfoCaptured, setDebitOrderInfoCaptured] = useState(false);
  const [debtCounsellorInfoCaptured, setDebtCounsellorInfoCaptured] =
    useState(false);
  const [declarationDataCaptured, setDeclarationDataCaptured] = useState(false);
  const [documentsUploadInfoCaptured, setDocumentsUploadInfoCaptured] =
    useState(false);
  const [personalInfoCaptured, setPersonalInfoCaptured] = useState(false);

  return (
    <MyContext.Provider
      value={{
        //Data
        data,
        setData,

        //Docs
        docsData,
        setDocsData,
        docsSubmitted,
        setDocsSubmitted,
        docsSent,
        setDocsSent,
        docsError,
        setDocsError,
        docsResponse,
        setDocsResponse,

        //Authentication Headers
        headers,
        setHeaders,

        //Laminin
        lamininSubmitted,
        setLamininSubmitted,
        lamininSent,
        setLamininSent,
        lamininError,
        setLamininError,
        lamininResponse,
        setLamininResponse,

        //Forms
        creditProvidersInfoCaptured,
        setCreditProvidersInfoCaptured,
        debitOrderInfoCaptured,
        setDebitOrderInfoCaptured,
        debtCounsellorInfoCaptured,
        setDebtCounsellorInfoCaptured,
        declarationDataCaptured,
        setDeclarationDataCaptured,
        documentsUploadInfoCaptured,
        setDocumentsUploadInfoCaptured,
        personalInfoCaptured,
        setPersonalInfoCaptured,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export default MyProvider;
