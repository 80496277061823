import React from "react";

// Create a context with default values
const MyContext = React.createContext({
  //Data
  data: {},

  //Docs
  docsData: {},
  docsSubmitted: false,
  docsSent: false,
  docsError: false,
  docsResponse: false,

  //Authentication Headers
  headers: null,

  //Laminin
  lamininSubmitted: false,
  lamininSent: false,
  lamininError: false,
  lamininResponse: "",

  //Forms
  creditProvidersInfoCaptured: false,
  debitOrderInfoCaptured: false,
  debtCounsellorInfoCaptured: false,
  declarationDataCaptured: false,
  documentsUploadInfoCaptured: false,
  personalInfoCaptured: false,
});

export default MyContext;
