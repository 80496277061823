import React from "react";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as SiIcons from "react-icons/si";

export const NavLinkData = [
  {
    title: "About",
    path: "#",
    icon: <FaIcons.FaInfoCircle />,
    iconClose: <RiIcons.RiArrowDownSFill />,
    iconOpen: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "About Us",
        path: "/about",
        icon: <SiIcons.SiLighthouse />,
      },
      {
        title: "Privacy Policy",
        path: "/privacypolicy",
        icon: <IoIcons.IoIosLock />,
      },
    ],
  },
  // {
  //   title: "Solutions",
  //   path: "#",
  //   icon: <FaIcons.FaLightbulb />,
  //   iconClose: <RiIcons.RiArrowDownSFill />,
  //   iconOpen: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Debt Counselling",
  //       path: "/debt-counselling",
  //       icon: <FaIcons.FaCommentDollar />,
  //     },
  //   ],
  // },
  // {
  //   title: "Tools",
  //   path: "#",
  //   icon: <FaIcons.FaTools />,
  //   iconClose: <RiIcons.RiArrowDownSFill />,
  //   iconOpen: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "Savings Calculator",
  //       path: "/savings-calculator",
  //       icon: <FaIcons.FaHandHoldingUsd />,
  //     },
  //     {
  //       title: "Overindebted Calculator",
  //       path: "/overindebted-calculator",
  //       icon: <FaIcons.FaCalculator />,
  //     },
  //     {
  //       title: "Resources",
  //       path: "/resources",
  //       icon: <SiIcons.SiKnowledgebase />,
  //     },
  //   ],
  // },
  // {
  //   title: "FAQs",
  //   path: "/faq",
  //   icon: <FaIcons.FaQuestionCircle />,
  // },
  {
    title: "Contact",
    path: "/contact",
    icon: <MdIcons.MdOutlineContactPhone />,
  },
];
