import styled from "styled-components";

export const Button = styled.button`
  border-radius: 50px;
  border: 1px solid #2f3c69;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.menu};
  font-size: medium;
  font-weight: 700;
  padding: 10px 40px;
  background-color: #2f3c69;
  color: #fff;
  text-decoration: none;

  @media ${({ theme }) => theme.mindevice.laptop} {
    font-size: large;
    padding: 15px 60px;
  }

  &:disabled {
    opacity: 0.9;
    cursor: default;
  }

  &:hover {
    background-color: #29345b;
    color: #fff;
  }
`;
