import React, { useState, useEffect, useRef } from "react";
import {
  NavDropdownArrowWrapper,
  NavDropdownLink,
  NavLinkLabel,
  NavLink,
  NavbarLinkContainer,
  NavLinkWrapper,
  NavDropdownLinkWrapper,
} from "./styles/Navbar.styled";

const NavLinks = (props) => {
  const [subnav, setSubnav] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (subnav && ref.current && !ref.current.contains(e.target)) {
        setSubnav(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [subnav]);

  function handleClick() {
    if (props.item.subNav) {
      setSubnav(!subnav);
    }
  }

  return (
    <NavbarLinkContainer onClick={handleClick} ref={ref}>
      <NavLinkWrapper to={props.item.path}>
        <NavLink
          changeColor={props.changeColor}
          extendNavbar={props.extendNavbar}
        >
          <NavLinkLabel>{props.item.title}</NavLinkLabel>
          <NavDropdownArrowWrapper>
            {props.item.subNav && subnav
              ? props.item.iconOpen
              : props.item.subNav
              ? props.item.iconClose
              : null}
          </NavDropdownArrowWrapper>
        </NavLink>
      </NavLinkWrapper>
      {subnav &&
        props.item.subNav.map((item, index) => {
          return (
            <NavDropdownLinkWrapper to={item.path} key={index}>
              <NavDropdownLink
                changeColor={props.changeColor}
                extendNavbar={props.extendNavbar}
              >
                {item.icon}
                <NavLinkLabel>{item.title}</NavLinkLabel>
              </NavDropdownLink>
            </NavDropdownLinkWrapper>
          );
        })}
    </NavbarLinkContainer>
  );
};

export default NavLinks;
