import styled from "styled-components";

export const Wrapper = styled.div`
  @media ${({ theme }) => theme.maxdevice.mobileM} {
    margin: 80px 1% 0;
  }

  @media ${({ theme }) => theme.maxdevice.laptop} {
    margin: 80px 5% 0;
  }

  @media ${({ theme }) => theme.mindevice.laptop} {
    margin: 80px 10% 0;
  }
`;
