import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  ExtendedNavDropdownLink,
  ExtendedNavLink,
  ExtendedNavLinkLabel,
  NavDropdownArrowWrapper,
} from "./styles/Navbar.styled";

const Wrapper = styled.div``;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ExtendedNavLinks = (props) => {
  const [subnav, setSubnav] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (subnav && ref.current && !ref.current.contains(e.target)) {
        setSubnav(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [subnav]);

  function handleLinkClick() {
    if (props.item.subNav) {
      setSubnav(!subnav);
    }
    if (!props.item.subNav) {
      props.setExtendNavbar(false);
    }
  }

  function handleDropdownClick() {
    props.setExtendNavbar(false);
  }

  return (
    <Wrapper ref={ref}>
      <ExtendedNavLink to={props.item.path} onClick={handleLinkClick}>
        <TextWrapper>
          {props.item.icon}
          <ExtendedNavLinkLabel>{props.item.title}</ExtendedNavLinkLabel>
        </TextWrapper>
        <NavDropdownArrowWrapper>
          {props.item.subNav && subnav
            ? props.item.iconOpen
            : props.item.subNav
            ? props.item.iconClose
            : null}
        </NavDropdownArrowWrapper>
      </ExtendedNavLink>
      {subnav &&
        props.item.subNav.map((item, index) => {
          return (
            <ExtendedNavDropdownLink
              to={item.path}
              key={index}
              onClick={handleDropdownClick}
            >
              {item.icon}
              <ExtendedNavLinkLabel>{item.title}</ExtendedNavLinkLabel>
            </ExtendedNavDropdownLink>
          );
        })}
    </Wrapper>
  );
};

export default ExtendedNavLinks;
