import React, { useContext, useState } from "react";
import axios from "axios";
import { Input } from "../components/styles/Input.styled";
import { TextArea } from "../components/styles/TextArea.styled";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Wrapper } from "../components/styles/Wrapper.styled";

//Context
import MyContext from "../context/MyContext";

const Background = styled.div`
  background-image: url("images/map.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const CustomWrapper = styled(Wrapper)`
  color: "#fff";
`;

const Heading = styled.div`
  padding: 40px 20px 20px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  color: #fff;
`;

const SubHeading = styled.div`
  padding: 10px 20px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 2rem;
  color: #fff;
`;

const SubHeadingRight = styled(SubHeading)`
  text-align: right;
`;

const Content = styled.div`
  padding: 10px 20px;
  font-family: Roboto, sans-serif;
  font-weight: 200;
  font-size: 1rem;
  color: #fff;
`;

const ContentRight = styled.div`
  text-align: right;
  padding: 10px 20px;
  color: #fff;
`;

const ContentAT = styled.a`
  padding: 10px 20px;
  font-family: Roboto, sans-serif;
  font-weight: 200;
  font-size: 1rem;
  color: #fff;
  text-decoration: none !important;
  background: transparent;
  border: none;
  outline: none;

  &:hover {
    color: #ec9307;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const Form = styled.form`
  padding: 10px 20px;
  font-family: Roboto, sans-serif;
`;

const ContactButton = styled.button`
  padding: 10px 20px;
  color: #fff;
  text-align: right;
  background: transparent;
  border: none;

  &:hover {
    color: #ec9307;
  }
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
`;

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  text-align: right;
`;

function Contact() {
  const [mail, setMail] = useState({
    subject: "BPO Group Website | Contact Request",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [mailSubmitted, setMailSubmitted] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState(false);

  const { headers } = useContext(MyContext);

  function handleChange(event) {
    const { name, value } = event.target;

    setMail((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
        subject: "BPO Group Website | Contact Request",
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setMailSubmitted(true);
    // Enable this for local testing environment
    axios
      // .post("http://localhost:5000/api/mail", mail, {
      // Enable this for hosted production environment
      .post("api/mail", mail, {
        headers: headers,
      })
      .then(function (res) {
        if (res.data === "Mail sent successfully") {
          setMailSent(true);
        } else {
          setMailError(true);
        }
      });
    setMail({
      subject: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      message: "",
    });
  }

  return (
    <Background>
      <CustomWrapper>
        <Helmet>
          <title>Contact</title>
          <meta
            name="description"
            description="BPO Group Contact Details: Web: www.bpogroup.co.za | Phone: 0100 545 575 | Email: info@bpogroup.co.za | Address: 315 Jorissen Street, Monument, Krugersdorp, 1739"
          />
        </Helmet>
        <Heading variant="h1" component="h1">
          Contact
        </Heading>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <SubHeading variant="h2" component="h2">
              Email Us
            </SubHeading>
            <Content
              variant="h5"
              component="p"
              hidden={mailError || mailSubmitted}
            >
              We would love to hear from you. Please complete the form below to
              send us an email.
            </Content>
            <Content
              variant="h5"
              component="p"
              hidden={mailError || !mailSubmitted}
            >
              {mailSent
                ? "Thank you for reaching out to us, we will contact you soon."
                : "Processing your request, please stand by..."}
            </Content>
            <Content variant="h5" component="p" hidden={!mailError}>
              Something went wrong and we did not receive your email. Please
              contact us on 0100 545 575. We apologise for the inconvenience.
            </Content>
            <Form onSubmit={handleSubmit}>
              <Input
                type="text"
                name="firstName"
                onChange={handleChange}
                placeholder="First Name"
                required={true}
                value={mail.firstName}
              />
              <Input
                type="text"
                name="lastName"
                onChange={handleChange}
                placeholder="Last Name"
                required={true}
                value={mail.lastName}
              />
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                placeholder="Email Address"
                required={true}
                value={mail.email}
              />
              <Input
                type="text"
                name="mobile"
                onChange={handleChange}
                placeholder="Mobile"
                required={true}
                value={mail.mobile}
              />
              <TextArea
                rows={4}
                cols={50}
                name="message"
                onChange={handleChange}
                placeholder="Message"
                required={true}
                value={mail.message}
              ></TextArea>
              <button
                className="btn btn-lg btn-pri width-full"
                type="submit"
                disabled={mailSubmitted && !mailSent ? true : false}
              >
                Send
              </button>
            </Form>
          </Grid>
          <Grid item md={6} xs={12}>
            <SubHeadingRight variant="h2" component="h2">
              Our Details
            </SubHeadingRight>
            <ContentRight variant="h5" component="p">
              Alternatively, you can contact us via:
            </ContentRight>
            <ContactContent>
              {/* <ContentAT
                href="https://bpogroup.freshdesk.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HelpIcon /> Customer Service
              </ContentAT> */}

              <ContentAT href="tel:0100545575">
                <PhoneIcon /> 0100 545 575
              </ContentAT>

              <ContentAT href="mailto:info@bpogroup.co.za">
                <EmailIcon /> info@bpogroup.co.za
              </ContentAT>

              {/* <ContentAT
                href="https://www.facebook.com/bpogroup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon /> Facebook
              </ContentAT> */}

              <ContactButton>
                <BusinessIcon /> 315 Jorissen Street
                <br />
                Monument
                <br />
                Krugersdorp
                <br />
                1739
              </ContactButton>
            </ContactContent>
          </Grid>
        </Grid>
      </CustomWrapper>
    </Background>
  );
}

export default Contact;
