import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import { Button } from "../components/styles/Button.styled";
import { Input } from "../components/styles/Input.styled";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import TypeWriter from "typewriter-effect";

//Context
import MyContext from "../context/MyContext";

//Styling
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const FormWrapper = styled.div`
  @media ${({ theme }) => theme.mindevice.tablet} {
    padding-bottom: 100px;
  }

  @media ${({ theme }) => theme.maxdevice.tablet} {
    padding-bottom: 30px;
  }
`;

const Left = styled(Grid)`
  @media ${({ theme }) => theme.maxdevice.tablet} {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  margin: auto;
  padding-bottom: 30px;
`;

const Right = styled(Grid)`
  @media ${({ theme }) => theme.maxdevice.tablet} {
    margin: 10px 0;
  }
  margin: auto;
`;

const TextWrapper = styled.div`
  color: #181e35;
  @media ${({ theme }) => theme.mindevice.tablet} {
    padding-top: 100px;
  }

  @media ${({ theme }) => theme.maxdevice.tablet} {
    padding-top: 30px;
  }
`;

const Wrapper = styled.div`
  @media ${({ theme }) => theme.maxdevice.tablet} {
    padding: 0;
  }

  @media ${({ theme }) => theme.mindevice.tablet} {
    background-image: url("images/technology.jpg");
    padding: 3% 10% 0%;
  }

  margin-top: 80px;
  text-align: left;
  color: white;
  background-color: grey;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

function Root() {
  const { headers, setHeaders } = useContext(MyContext);

  const [lead, setLead] = useState({
    FirstName: "",
    LastName: "",
    Mobile: "",
  });

  const [leadSubmitted, setLeadSubmitted] = useState(false);
  const [leadReceived, setLeadReceived] = useState(false);
  const [errorReceived, setErrorReceived] = useState(false);

  const [mailSubmitted, setMailSubmitted] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState(false);

  const typeWriterText = [
    "Business process outsourcing?",
    "Contact centre delivery?",
    "Direct marketing?",
    "Account management?",
  ];

  let mailLead = {
    subject: "BPO Group Website | Contact Request",
    firstName: "",
    lastName: "",
    mobile: "",
  };

  const [count, setCount] = useState(0);

  //Authenticate
  useEffect(() => {
    if (headers === null && count < 10) {
      axios
        // Enable this for local testing environment
        // .get("http://localhost:5000/api/authenticate")
        // Enable this for hosted production environment
        .get("api/authenticate")
        .then(
          (response) => {
            if (response.status === 200) {
              setHeaders({
                Authorization: "Bearer " + response.data.token,
                "Content-Type": "application/json",
              });
            } else {
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
          }
        );
      setCount((count) => count + 1);
    }
  }, [count, headers, setHeaders]);

  function handleLeadChange(event) {
    const { name, value } = event.target;

    setLead((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleLeadSubmit(event) {
    event.preventDefault();
    checkPhoneNumber();
  }

  function checkPhoneNumber() {
    const phoneNumber = /^\d{10}$/;
    if (lead.Mobile.match(phoneNumber)) {
      submitToLaminin();
      submitToMail();
      setLead({
        FirstName: "",
        LastName: "",
        Mobile: "",
      });
    } else {
      alert(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
    }
  }

  function submitToLaminin() {
    setLeadSubmitted(true);
    axios
      // Enable this for local testing environment
      // .post("http://localhost:5000/api/laminin/create", lead, {
      // Enable this for hosted production environment
      .post("api/laminin/create", lead, {
        headers: headers,
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setLeadReceived(true);
          } else {
            console.log(response.status);
            setErrorReceived(true);
          }
        },
        (error) => {
          console.log(error);
          setErrorReceived(true);
        }
      );
  }

  function submitToMail() {
    setMailSubmitted(true);

    mailLead = {
      subject: "BPO Group Website | Lead from Landing Page",
      firstName: lead.FirstName,
      lastName: lead.LastName,
      mobile: lead.Mobile,
      message:
        "Client submitted their info through the Debt Haven website landing page. Please check the Debt Haven Website campaign on Laminin, there should be a new lead.",
    };

    // Enable this for local testing environment
    axios
      // .post("http://localhost:5000/api/mail", mailLead, {
      // Enable this for hosted production environment
      .post("api/mail", mailLead, {
        headers: headers,
      })
      .then(
        (response) => {
          console.log("Mail Response: " + response);
          if (response.status === 200) {
            console.log("Mail Response OK: " + response.status);
            setMailSent(true);
          } else {
            console.log("Mail Response Not OK: " + response.status);
            setMailError(true);
          }
        },
        (error) => {
          console.log("Mail Response Error: " + error);
          setMailError(true);
        }
      );
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Welcome | BPO</title>
        <meta
          name="description"
          description="Not coping with your current credit commitments despite numerous attempts? Consult one of our friendly Counsellors today!"
        />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Helmet>
      <Grid container spacing={2}>
        <Left item md={6}>
          <TextWrapper>
            <h1 className="display-6 cover-heading">Do you require</h1>
            <p className="display-6 cover-heading">
              <TypeWriter
                options={{
                  strings: typeWriterText,
                  autoStart: true,
                  loop: true,
                  pauseFor: 2000,
                  deleteSpeed: 35,
                }}
              />
            </p>
          </TextWrapper>
        </Left>
        <Right item md={6}>
          <TextWrapper>
            <h1 className="display-6 cover-heading">Contact Us</h1>
            <h3
              className="display-7 cover-medium-heading cover-extra-padding-bottom"
              hidden={errorReceived || leadSubmitted}
            >
              Please complete the form below
            </h3>
            <h3
              className="display-7 cover-medium-heading cover-extra-padding-bottom"
              hidden={errorReceived || !leadSubmitted}
            >
              {leadReceived
                ? "Congratulations on starting your Debt-Free Journey! A Debt Counsellor will call you shortly."
                : "Processing your request, please stand by..."}
            </h3>
            <h3
              className="display-7 cover-medium-heading cover-extra-padding-bottom"
              hidden={!errorReceived}
            >
              Something went wrong and we did not receive your information.
              Please click{" "}
              <Link className="cover-at" to="/contact">
                here
              </Link>{" "}
              for more contact options. We apologise for the inconvenience.
            </h3>
          </TextWrapper>
          <FormWrapper>
            <form
              className="debt-form"
              onSubmit={handleLeadSubmit}
              autoComplete="off"
            >
              <Input
                type="text"
                placeholder="First Name"
                onChange={handleLeadChange}
                name="FirstName"
                value={lead.FirstName}
                required={true}
              />
              <Input
                type="text"
                placeholder="Last Name"
                onChange={handleLeadChange}
                name="LastName"
                value={lead.LastName}
                required={true}
              />
              <Input
                type="tel"
                placeholder="Mobile"
                onChange={handleLeadChange}
                name="Mobile"
                value={lead.Mobile}
                required={true}
              />
              <p className="cover-tc">
                We keep all your information completely confidential.
                <br />
                <Link className="cover-at" to="/privacypolicy">
                  Privacy Policy
                </Link>
              </p>
              <ButtonWrapper>
                <Button
                  type="submit"
                  disabled={leadSubmitted && !leadReceived ? true : false}
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </form>
          </FormWrapper>
        </Right>
      </Grid>
    </Wrapper>
  );
}

export default Root;
