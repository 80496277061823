import React from "react";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Wrapper } from "../components/styles/Wrapper.styled";
import { Heading } from "../components/styles/Heading.styled";
import { Body } from "../components/styles/Body.styled";
import styled from "styled-components";

const CustomAccordion = styled(Accordion)`
  padding: 0 4px;
`;

const About = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          description="Founded in 2012, BPO Group is an innovative business process outsourcing, contact centre delivery, direct marketing and account management company."
        />
      </Helmet>
      <Heading variant="h1" gutterBottom>
        About Us
      </Heading>
      <Body elevation={3}>
        <Typography variant="body2" gutterBottom>
          Founded in 2012, BPO Group is an innovative business process
          outsourcing, contact centre delivery, direct marketing and account
          management company.
        </Typography>
        <br />
        <Typography variant="body2" gutterBottom>
          We offer a complete suite of Outbound, Inbound and Back-Office
          solutions, which can help build your brand, improve customer service,
          increase your sales and streamline operations whilst cutting your
          operating costs.
        </Typography>
        <br />
        <Typography variant="body2" gutterBottom></Typography>
      </Body>
      <CustomAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body2" gutterBottom>
            <strong>Our Mission</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" gutterBottom>
            Add Mission Statement
          </Typography>
        </AccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="body2" gutterBottom>
            <strong>Our Vision</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" gutterBottom>
            Add Vision Statement
          </Typography>
        </AccordionDetails>
      </CustomAccordion>
    </Wrapper>
  );
};

export default About;
